import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import UserManagementPage from "./pages/UserManagementPage";
import DashboardLayout from "./components/Layout/DashboardLayout";
import UserCreationForm from "./pages/UserCreationForm";
import AddLogForm from "./pages/AddLogForm";
import LogManagementPage from "./pages/LogManagementForm";
import SpeciesManagementPage from "./pages/SpeciesMaster/SpeciesManagement";
import AddSpeciesForm from "./pages/SpeciesMaster/AddSpecies";
import CategoryManagementPage from "./pages/CategoryMaster/CategoryManagement";
import AddCategory from "./pages/CategoryMaster/AddCategory";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
      

         {/* Authenticated Routes */}
         <Route path="/" element={<DashboardLayout />}>

          <Route path="dashboard" element={<Dashboard />} />
          {/* Users */}
          <Route path="userList" element={<UserManagementPage />} />
          <Route path="createUser" element={<UserCreationForm />} />
          {/* Logs */}
          <Route path="logManagement" element={<LogManagementPage />} />
          <Route path="addLogs" element={<AddLogForm />} />
          {/* Species */}
          <Route path="speciesManagement" element={<SpeciesManagementPage />} />
          <Route path="addSpecies" element={<AddSpeciesForm />} />

          {/* Category */}
          <Route path="categoryManagement" element={<CategoryManagementPage />} />
          <Route path="addCategory" element={<AddCategory />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
