import React, { useState } from "react";
import { HomeIcon, CogIcon, UserGroupIcon, AcademicCapIcon, BriefcaseIcon, DocumentIcon, DocumentAddIcon,FolderIcon,CubeIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import logo from '../../assets/logo.jpg'
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const [masterOpen, setMasterOpen] = React.useState(false);
  const [woodCategoryOpen, setWoodCategoryOpen] = useState(false);

  return (
    <div className="fixed top-0 left-0 h-screen w-64 bg-gradient-to-b from-blue-900 to-blue-800 text-white shadow-lg">
      {/* Logo Section */}
      <div className="flex items-center justify-center h-20 border-b border-blue-700">
        <img
          src={logo}
          alt="Logo"
          className="w-16 h-16 rounded-full shadow-lg"
        />
      </div>

      {/* Navigation Menu */}
      <nav className="mt-4">
        <ul className="space-y-2">
          {/* Dashboard */}
          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <HomeIcon className="w-5 h-5 text-white mr-3" />
              Dashboard
            </a>
          </li>

          <li className="group">
          <NavLink
              to="/userList"
              className={({ isActive }) =>
                `flex items-center px-4 py-3 text-sm font-medium rounded ${
                  isActive ? "bg-blue-800" : "hover:bg-blue-700"
                }`
              }
            >
              <HomeIcon className="w-5 h-5 text-white mr-3" />
              User Creation
            </NavLink>
          </li>


{/* Master Section */}
<li>
            <button
              onClick={() => setMasterOpen(!masterOpen)}
              className="flex items-center justify-between w-full px-4 py-3 text-sm font-medium rounded hover:bg-blue-700"
            >
              <span className="flex items-center">
                <FolderIcon className="w-5 h-5 text-white mr-3" />
                Master
              </span>
              {masterOpen ? (
                <ChevronUpIcon className="w-5 h-5 text-white" />
              ) : (
                <ChevronDownIcon className="w-5 h-5 text-white" />
              )}
            </button>
            {/* Master Submenu */}
            {masterOpen && (
              <ul className="ml-8 mt-2 space-y-2">
                {/* Wood Category Section */}
                <li>
                  <button
                    onClick={() => setWoodCategoryOpen(!woodCategoryOpen)}
                    className="flex items-center justify-between w-full px-4 py-3 text-sm font-medium rounded hover:bg-blue-700"
                  >
                    <span className="flex items-center">
                      <CubeIcon className="w-5 h-5 text-white mr-3" />
                      Wood Category
                    </span>
                    {woodCategoryOpen ? (
                      <ChevronUpIcon className="w-5 h-5 text-white" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5 text-white" />
                    )}
                  </button>

                  {/* Wood Category Submenu */}
                  {woodCategoryOpen && (
                    <ul className="ml-8 mt-2 space-y-2">
                      <li>
                        <NavLink
                          to="/speciesManagement"
                          className={({ isActive }) =>
                            `block px-4 py-2 text-sm font-medium rounded ${
                              isActive ? "bg-blue-800" : "hover:bg-blue-700"
                            }`
                          }
                        >
                          Species
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            )}
          </li>

          <li className="group">
            <a
              href="/categoryManagement"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <DocumentAddIcon className="w-5 h-5 text-white mr-3" />
              Category Master
            </a>
          </li>

          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <DocumentAddIcon className="w-5 h-5 text-white mr-3" />
              Mill Master
            </a>
          </li>

          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <DocumentIcon className="w-5 h-5 text-white mr-3" />
              Location Master
            </a>
          </li>

          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <DocumentAddIcon className="w-5 h-5 text-white mr-3" />
              Size Master
            </a>
          </li>
          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <DocumentIcon className="w-5 h-5 text-white mr-3" />
              Product Master
            </a>
          </li>
          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <BriefcaseIcon className="w-5 h-5 text-white mr-3" />
              Lot Master
            </a>
          </li>


          {/* Users */}
          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <UserGroupIcon className="w-5 h-5 text-white mr-3" />
              Reports
            </a>
          </li>

          {/* General */}
          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <AcademicCapIcon className="w-5 h-5 text-white mr-3" />
              General
            </a>
          </li>

          {/* Settings */}
          <li className="group">
            <a
              href="/dashboard"
              className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
            >
              <CogIcon className="w-5 h-5 text-white mr-3" />
              Settings
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
