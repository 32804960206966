import React from "react";
import { TrendingUpIcon } from "@heroicons/react/outline";


const AnalyticsCards = () => {
    const data = [
      {
        title: "Cash Deposits",
        value: "1.7M",
        icon: <TrendingUpIcon className="w-8 h-8 text-blue-600" />,
        color: "bg-blue-100",
        textColor: "text-blue-600",
      },
      {
        title: "Invested Dividends",
        value: "9M",
        icon: <TrendingUpIcon className="w-8 h-8 text-blue-600" />,
        color: "bg-green-100",
        textColor: "text-green-600",
      },
      {
        title: "Capital Gains",
        value: "$563",
        icon: <TrendingUpIcon className="w-8 h-8 text-blue-600" />,
        color: "bg-yellow-100",
        textColor: "text-yellow-600",
      },
    ];
  
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-6">
        {data.map((item, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 flex items-center space-x-4 hover:shadow-xl transition-shadow duration-300"
          >
            {/* Icon */}
            <div className={`p-3 rounded-full ${item.color}`}>
              <span className={`material-icons ${item.textColor} text-3xl`}>
                {item.icon}
              </span>
            </div>
  
            {/* Text Content */}
            <div>
              <h3 className="text-gray-500 text-sm font-medium">{item.title}</h3>
              <p className="text-2xl font-bold">{item.value}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default AnalyticsCards;
  