import React from "react";
import Sidebar from "../Layout/Sidebar"; // Your Sidebar component
import TopNav from "../Layout/TopNav"; // Your TopNav component
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  return (
    <div className="flex bg-gray-100 min-h-screen">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 ml-64">
        {/* Top Navigation Bar */}
        <TopNav />

        {/* Page Content */}
        <div className="mt-16 p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
