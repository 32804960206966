// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2B3990", // Blue from the logo
    },
    secondary: {
      main: "#1EAD5A", // Green from the logo
    },
    background: {
      default: "#F5F5F5", // Light gray for the page background
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    h4: {
      fontWeight: 700,
    },
    body1: {
      color: "#333", // Neutral color for body text
    },
  },
});

export default theme;
