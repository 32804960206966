import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddLogForm = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Form Data:", { ...data, date: selectedDate });
    alert("Log Added Successfully!");
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Add Log</h1>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow rounded-lg p-6 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Location</label>
            <input
              {...register("location", { required: "Location is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.location && <p className="text-red-600 text-sm">{errors.location.message}</p>}
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Lot Number</label>
            <input
              {...register("lotno", { required: "Lot number is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.lotno && <p className="text-red-600 text-sm">{errors.lotno.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Date</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select a date"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {!selectedDate && <p className="text-red-600 text-sm">Date is required</p>}
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Vassel</label>
            <input
              {...register("vassel", { required: "Vassel is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.vassel && <p className="text-red-600 text-sm">{errors.vassel.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Supplier</label>
            <input
              {...register("supplier", { required: "Supplier is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.supplier && <p className="text-red-600 text-sm">{errors.supplier.message}</p>}
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Gatepass Number</label>
            <input
              {...register("gatepassno", { required: "Gatepass number is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.gatepassno && <p className="text-red-600 text-sm">{errors.gatepassno.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Number of Logs</label>
            <input
              {...register("noOfLogs", { required: "Number of logs is required" })}
              type="number"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.noOfLogs && <p className="text-red-600 text-sm">{errors.noOfLogs.message}</p>}
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Grade</label>
            <input
              {...register("grade", { required: "Grade is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.grade && <p className="text-red-600 text-sm">{errors.grade.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Length</label>
            <input
              {...register("length", { required: "Length is required" })}
              type="number"
              step="0.01"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.length && <p className="text-red-600 text-sm">{errors.length.message}</p>}
          </div>
          <div>
            <label className="block text-gray-600 mb-2">SED</label>
            <input
              {...register("sed", { required: "SED is required" })}
              type="number"
              step="0.01"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.sed && <p className="text-red-600 text-sm">{errors.sed.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Volume</label>
            <input
              {...register("volume", { required: "Volume is required" })}
              type="number"
              step="0.01"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.volume && <p className="text-red-600 text-sm">{errors.volume.message}</p>}
          </div>
          <div>
            <label className="block text-gray-600 mb-2">PCS</label>
            <input
              {...register("pcs", { required: "PCS is required" })}
              type="number"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.pcs && <p className="text-red-600 text-sm">{errors.pcs.message}</p>}
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-transform transform hover:scale-105"
          >
            Add Log
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddLogForm;
