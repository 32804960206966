import React from "react";
import AnalyticsCards from "../components/AnalyticsCards";
import Sidebar from "../components/Layout/Sidebar";
import TopNav from "../components/Layout/TopNav";

const Dashboard = () => {
  return (
    <div className="flex bg-gray-100 min-h-screen">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1">
        <TopNav />
        <main className="mt-1 p-6">
          <AnalyticsCards />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
