import React from "react";

const TopNav = () => {
  return (
    <div className="fixed top-0 left-64 w-[calc(100%-16rem)] h-16 bg-white shadow-md flex items-center justify-between px-6">
      {/* Left Section */}
      <div className="flex items-center space-x-4">
        
      </div>

      {/* Right Section */}
      <div className="flex items-center space-x-6">
        <button className="text-gray-500 hover:text-blue-600">
          <span className="material-icons">notifications</span>
        </button>
        <button className="text-gray-500 hover:text-blue-600">
          <span className="material-icons">settings</span>
        </button>
        <img
          src="/path/to/profile.jpg"
          alt="Profile"
          className="w-8 h-8 rounded-full shadow-md"
        />
      </div>
    </div>
  );
};

export default TopNav;
