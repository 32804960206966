import React from "react";
import { useForm } from "react-hook-form";

const UserCreationForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    alert("User Created Successfully!");
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Create User</h1>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow rounded-lg p-6 space-y-6">
        {/* Name and Username */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Name</label>
            <input
              {...register("name", { required: "Name is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.name && <p className="text-red-600 text-sm">{errors.name.message}</p>}
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Username</label>
            <input
              {...register("username", { required: "Username is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.username && <p className="text-red-600 text-sm">{errors.username.message}</p>}
          </div>
        </div>

        {/* Mobile Number and Email */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Mobile Number</label>
            <input
              {...register("mobileNo", { required: "Mobile number is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.mobileNo && (
              <p className="text-red-600 text-sm">{errors.mobileNo.message}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Email</label>
            <input
              {...register("email", { required: "Email is required" })}
              type="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.email && <p className="text-red-600 text-sm">{errors.email.message}</p>}
          </div>
        </div>

        {/* Password */}
        <div>
          <label className="block text-gray-600 mb-2">Password</label>
          <input
            {...register("password", { required: "Password is required" })}
            type="password"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
          />
          {errors.password && <p className="text-red-600 text-sm">{errors.password.message}</p>}
        </div>

        {/* Access Rights */}
        <div>
          <label className="block text-gray-600 mb-2">Access Rights</label>
          <div className="flex items-center space-x-6">
            <label className="flex items-center">
              <input
                {...register("accessRights", { required: "At least one access right is required" })}
                type="checkbox"
                value="Admin"
                className="mr-2"
              />
              Admin
            </label>
            <label className="flex items-center">
              <input
                {...register("accessRights", { required: "At least one access right is required" })}
                type="checkbox"
                value="Editor"
                className="mr-2"
              />
              Editor
            </label>
            <label className="flex items-center">
              <input
                {...register("accessRights", { required: "At least one access right is required" })}
                type="checkbox"
                value="Viewer"
                className="mr-2"
              />
              Viewer
            </label>
          </div>
          {errors.accessRights && (
            <p className="text-red-600 text-sm">{errors.accessRights.message}</p>
          )}
        </div>

        {/* Submit Button */}
        <div className="text-left">
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-transform transform hover:scale-105"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserCreationForm;
