import React from "react";
import { useForm } from "react-hook-form";

const AddSpeciesForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    alert("Species Added Successfully!");
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Add Species</h1>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow rounded-lg p-6 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Name */}
          <div>
            <label className="block text-gray-600 mb-2">Name</label>
            <input
              {...register("name", { required: "Name is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.name && <p className="text-red-600 text-sm">{errors.name.message}</p>}
          </div>

          {/* Code */}
          <div>
            <label className="block text-gray-600 mb-2">Code</label>
            <input
              {...register("code", { required: "Code is required" })}
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.code && <p className="text-red-600 text-sm">{errors.code.message}</p>}
          </div>
        </div>

        {/* Type */}
        <div>
          <label className="block text-gray-600 mb-2">Type</label>
          <div className="flex items-center space-x-6">
            <label className="flex items-center">
              <input
                {...register("type", { required: "Type is required" })}
                type="radio"
                value="Pine"
                className="mr-2"
              />
              Pine
            </label>
            <label className="flex items-center">
              <input
                {...register("type", { required: "Type is required" })}
                type="radio"
                value="Hardwood"
                className="mr-2"
              />
              Hardwood
            </label>
          </div>
          {errors.type && <p className="text-red-600 text-sm">{errors.type.message}</p>}
        </div>

        {/* Submit Button */}
        <div className="text-left">
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-transform transform hover:scale-105"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSpeciesForm;
