// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import App from "./App";
import theme from "./theme";
import "./index.css"; // Ensure Tailwind CSS is imported here


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Provides a consistent baseline */}
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
