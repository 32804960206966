import React, { useMemo } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { EyeIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const UserManagementPage = () => {
  // Sample user data
  const data = useMemo(
    () => [
      { id: 1, name: "John Doe", email: "john@example.com", role: "Admin" },
      { id: 2, name: "Jane Smith", email: "jane@example.com", role: "Editor" },
      { id: 3, name: "Alice Johnson", email: "alice@example.com", role: "Viewer" },
      { id: 4, name: "Bob Brown", email: "bob@example.com", role: "Editor" },
    ],
    []
  );

  // Define table columns
  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Role", accessor: "role" },
      {
        Header: "Actions",
        Cell: ({ row }) => (
         // Inside the Actions Cell
<div className="flex space-x-2">
  <button
    onClick={() => alert(`View user: ${row.original.name}`)}
    className="text-blue-600 hover:underline flex items-center"
  >
    <EyeIcon className="w-5 h-5 mr-1" />
    View
  </button>
  <button
    onClick={() => alert(`Edit user: ${row.original.name}`)}
    className="text-green-600 hover:underline flex items-center"
  >
    <PencilIcon className="w-5 h-5 mr-1" />
    Edit
  </button>
  <button
    onClick={() => alert(`Delete user: ${row.original.name}`)}
    className="text-red-600 hover:underline flex items-center"
  >
    <TrashIcon className="w-5 h-5 mr-1" />
    Delete
  </button>
</div>
        ),
      },
    ],
    []
  );

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  return (
    <div className="p-6">
      {/* Page Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">User Management</h1>
        <Link
          to="/createUser"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
        >
  <PlusIcon className="w-5 h-5 mr-2" />
  Add User
</Link>
      </div>

      {/* Search Filter */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search users..."
          value={state.globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* User Table */}
      <div className="overflow-x-auto bg-white shadow rounded-lg">
        <table
          {...getTableProps()}
          className="min-w-full bg-white border border-gray-200"
        >
          <thead className="bg-gray-100">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="border-b last:border-none hover:bg-gray-50"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 py-2 text-sm text-gray-800"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserManagementPage;
