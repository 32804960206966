import React from "react";
import { useForm } from "react-hook-form";

const AddCategory = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Category Created:", data);
    alert("Category Created Successfully!");
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Create Category</h1>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow rounded-lg p-6 space-y-6">
        {/* Category Name */}
        <div>
          <label className="block text-gray-600 mb-2">Category Name</label>
          <input
            {...register("categoryName", { required: "Category Name is required" })}
            type="text"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
          />
          {errors.categoryName && (
            <p className="text-red-600 text-sm">{errors.categoryName.message}</p>
          )}
        </div>

        {/* Submit Button */}
        <div className="text-right">
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-transform transform hover:scale-105"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCategory;
